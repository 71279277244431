import React from "react";
import Routing from "./routes/Routing";
const App = () => {
  return (
    <div>
      <Routing />
    </div>
  );
};

export default App;
